<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="row">
        <div class="col d-flex">
          <h3 v-text="title" class="my-auto"></h3>
        </div>
        <div class="col-auto">
          <v-btn
            color="secondary"
            class="text-none elevation-0 px-5"
            @click="openActivityModal()"
            dark
          >
            Nuevo
          </v-btn>
        </div>
      </div>
    </div>
    <div class="col-12">
      <data-table
        ref="secondSectionTable"
        :url="url"
        :key="url"
        :params="params"
        :headers="headersSecondSection"
        :mobile="true"
        :withoutPagination="true"
      >
        <template v-slot:item.arrows="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!item.last"
                large
                color="black"
                class="ml-5"
                center
                v-bind="attrs"
                v-on="on"
                @click="onChangeOrderActivity(item, 0)"
              >
                fa fa-angle-down
              </v-icon>
              <v-icon large class="ml-10 pl-1" v-else></v-icon>
            </template>
            <span>Subir Posición</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!item.first"
                large
                color="black"
                class="ml-5"
                center
                v-bind="attrs"
                v-on="on"
                @click="onChangeOrderActivity(item, 1)"
              >
                fa fa-angle-up
              </v-icon>
              <v-icon large class="ml-5" v-else></v-icon>
            </template>
            <span>Bajar Posición</span>
          </v-tooltip>
        </template>
        <template v-slot:item.isActive="{ item }">
          <v-switch
            color="accent"
            v-model="item.isActive"
            dense
            hide-details
            class="ma-0"
            @click="onChangeStatusActivity(item)"
          ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="ma-2 elevation-0"
                v-bind="attrs"
                v-on="on"
                @click="openActivityModal(item)"
                small
                color="primary"
              >
                <v-icon center dark small>
                  fa fa-edit
                </v-icon>
              </v-btn>
            </template>
            <span>Editar Actividad</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="ma-2 elevation-0"
                v-bind="attrs"
                v-on="on"
                @click="onDeleteActivity(item)"
                small
                color="primary"
              >
                <v-icon center dark small>
                  fa fa-trash
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar Actividad</span>
          </v-tooltip>
        </template>
      </data-table>
      <second-section-modal
        ref="secondSectionModal"
        :fairId="fair.id"
        :url="url"
        @onClose="onClose"
      />
      <confirm-dialog
        :title="'Confirmación'"
        :description="
          `¿Deseas ${
            Boolean(selectedActivity.isActive) ? 'activar' : 'desactivar'
          } la actividad?`
        "
        @onClose="closeChangeStatusActivityModal()"
        @onConfirm="changeStatusActivity()"
        :is-active="isConfirmChangeStatusActivity"
      />
      <confirm-dialog
        :title="'Confirmación'"
        :description="`¿Deseas eliminar la actividad ${selectedActivity.name}?`"
        @onClose="closeDeleteActivityModal()"
        @onConfirm="deleteActivity()"
        :is-active="isConfirmDeleteActivity"
      />
    </div>
  </div>
</template>

<script>
import headersSecondSection from '../data/HeadersSecondSection'
import DataTable from '@/components/data-table/DataTable'
import SecondSectionModal from './SecondSectionModal'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import { GENERAL_INFORMATION_URL } from '@/constants/ServicesMobileConstants'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  name: 'SecondSection',
  components: {
    ConfirmDialog,
    DataTable,
    SecondSectionModal
  },
  props: ['fair', 'section'],
  data () {
    return {
      params: {
        fairId: this.fair.id
      },
      url: GENERAL_INFORMATION_URL + '/blocks',
      selectedActivity: {},
      headersSecondSection,
      isConfirmChangeStatusActivity: false,
      isConfirmDeleteActivity: false
    }
  },
  watch: {
    section: {
      handler (value) {
        if (!value.id) { return }
        this.url = GENERAL_INFORMATION_URL + `/blocks/${value.id}/items`
        this.$refs.secondSectionTable.getDataFromApi()
      }
    }
  },
  computed: {
    title () {
      if (!this.section?.name) {
        return ''
      }

      return this.section.name
    }
  },
  methods: {
    isActive (isActive) {
      return Boolean(isActive)
    },
    onClose () {
      this.$refs.secondSectionTable.getDataFromApi()
    },
    openActivityModal (item) {
      this.$refs.secondSectionModal.open(item)
    },
    onChangeStatusActivity (item) {
      this.selectedActivity = item
      this.isConfirmChangeStatusActivity = true
    },
    onChangeOrderActivity (item, toMove) {
      this.selectedActivity = item
      this.selectedActivity.toMove = toMove
      this.changeOrderActivity()
    },
    onDeleteActivity (item) {
      this.selectedActivity = item
      this.isConfirmDeleteActivity = true
    },
    changeStatusActivity () {
      this.isConfirmChangeStatusActivity = false
      this.putMobile(
        this.url + '/' + this.selectedActivity.id + '/change-status',
        this.selectedActivity
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.secondSectionTable.getDataFromApi()
      })
    },
    deleteActivity () {
      this.isConfirmDeleteActivity = false
      this.deleteMobile(this.url, this.selectedActivity.id).then(
        res => {
          this.showSuccess(res.data.success)
          this.$refs.secondSectionTable.getDataFromApi()
        }
      )
    },
    changeOrderActivity () {
      this.getMobile(
        this.url + '/' +
        this.selectedActivity.id +
        '/change-order/' +
        this.selectedActivity.toMove
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.secondSectionTable.getDataFromApi()
      })
    },
    closeChangeStatusActivityModal () {
      this.isConfirmChangeStatusActivity = false
      this.selectedActivity.isActive = !this.selectedActivity.isActive
    },
    closeDeleteActivityModal () {
      this.isConfirmDeleteActivity = false
    }
  },
  mixins: [crudServiceMixin, crudServiceMobileMixin, notificationMixin]
}
</script>
